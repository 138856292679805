.imagewithtext-con {
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  .label {
    color: white;
  }

  .h2 {
    font-size: 12px;
    font-family: "poppinsSemiBold";
    color: white;
    margin: 0;
    line-height: 1;
  }
  .value {
    display: flex;
    align-items: center;
    align-content: center;
  }

  .value-image {
    height: 24px;
    width: 24px;
    // filter: invert(100%) brightness(100%);
  }
}
