.dropdown-con {
  display: flex;
  flex-direction: row;
  align-items: center;

  .dropdown-text {
    font-family: "LatoRegular";
    font-size: 22px;
    margin-right: 12px;

    color: var(--theme_primary_color);
  }
}
