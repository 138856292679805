.navtextStyle {
  color: var(--w1) !important;
}

.naviconStyle {
  color: var(--w1) !important;
}

.navBtnStyle {
  color: var(--w1) !important;
}

.activeRoute .overview path {
  fill: var(--theme_color);
}
