.userstatuscard-con {
  background: black;
  border-radius: 50px;
  min-width: 100px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 2px 5px 2px;
  display: flex;
  justify-content: center;
  align-content: center;
  .label{
    color: white;
  }
  
  .h2 {
    font-size: 12px;
    font-family: "poppinsSemiBold";
    color: white;
    margin: 0; 
    line-height: 1; 
  }
  .value {
    display: flex;
    align-items: center;
    align-content: center;
  }

  .value-image {
    margin-right: 5px;
  }

}

