.plaintextcard-con {
  margin-right: 10px;
  width: 250px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #bcbec0;
}
