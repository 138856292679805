.callout-main {
  padding: 16px;
  background-color: #6c82a6;
  border-radius: 12px;
  .callout-img {
    border-radius: 8px;
    height: 40px;
    width: 40px;
  }
  .icon-img {
    height: 20px;
    width: 20px;
  }
}
.callout-main {
  padding: 16px;
  background-color: #6c82a6;
  border-radius: 12px;
  width: 400px;
  .callout-img {
    border-radius: 8px;
    height: 40px;
    width: 40px;
  }
  .icon-img {
    height: 20px;
    width: 20px;
  }
  .icon-div{
    background-color: var(--theme_primary_color);
    padding:  12px;
    border-radius: 8px;
    margin-left: 10px,
  }
  .button-div{
    background-color: var(--theme_primary_color);
    padding:  8px 16px;
    border-radius: 8px;
    
  }
  .row-con{
    display: flex;
    flex-direction:"column";
    justify-content: space-between;
  }
  .left-div{
    display: flex;
    flex-direction:"column";
    align-items: center;
  }
  .inner-text{
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--w3)
  }

  .third-row{
    display: flex;
    flex-direction:"column";
    justify-content: space-between;
    background-color: var(--g3);
    border: 1px;
    border-radius: 8px;
    color : white;
    align-items: center;
    padding: 10px 16px;
    margin-top: 10px;
  }
  .text{
    font-size: 16px;
    font-weight: 700;
    
  }
}
