@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsMedium"), url("../../fonts//TTF/Poppins-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"), url("../../fonts//TTF/Poppins-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"), url("../../fonts//TTF/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"), url("../../fonts//TTF/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsMedium"), url("../../fonts//TTF/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsMedium"), url("../../fonts//TTF/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "LatoRegular";
  src: local("LatoRegular"), url("../../fonts/TTF/Lato-Regular.ttf");
}
@font-face {
  font-family: "LatoBlackItalic";
  src: local("LatoBlackItalic"), url("../../fonts/TTF/Lato-BlackItalic.ttf");
}
@font-face {
  font-family: "LatoBold";
  src: local("LatoBold"), url("../../fonts/TTF/Lato-Bold.ttf");
}
@font-face {
  font-family: "LatoBoldItalic";
  src: local("LatoBoldItalic"), url("../../fonts/TTF/Lato-BoldItalic.ttf");
}
@font-face {
  font-family: "LatoItalic";
  src: local("LatoItalic"), url("../../fonts/TTF/Lato-Italic.ttf");
}
@font-face {
  font-family: "LatoLight";
  src: local("LatoLight"), url("../../fonts/TTF/Lato-Light.ttf");
}
@font-face {
  font-family: "LatoLightItalic";
  src: local("LatoLightItalic"), url("../../fonts/TTF/Lato-LightItalic.ttf");
}
@font-face {
  font-family: "LatoThin";
  src: local("LatoThin"), url("../../fonts/TTF/Lato-Thin.ttf");
}
@font-face {
  font-family: "LatoThinItalic";
  src: local("LatoThinItalic"), url("../../fonts/TTF/Lato-ThinItalic.ttf");
}
