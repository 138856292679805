.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .Background-overlay {
    // background: rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .Auth-form {
    width: 450px;
    padding-top: 100px;
    padding-bottom: 100px;
    border-radius: 12px;
    background: var(--grd2);
    border: 2px solid var(--grd1);
    backdrop-filter: blur(2px);
    .Auth-form-content {
      padding-left: 12%;
      padding-right: 12%;
      .Auth-form-title {
        text-align: center;
        margin-bottom: 50px;
        font-size: 24px;
        color: var(--w3);
        font-weight: 800;
      }
      .labelText {
        color: #252525;
        font-size: 14px;
      }
      .btn-con {
        margin-top: 40px;
      }
      .button-style {
        position: relative;
        color: #ffffff;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .loader {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #252525;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      .linkText {
        color: #252525;
        font-size: 14px;
        margin-top: 10px;
        text-decoration: underline;
        padding-left: 5px;
      }
    }
  }
}

.background-image-con {
  background-image: url("../../images/loginBack.png");

  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.headCon {
  align-items: center;
  justify-content: space-between;
  display: flex;
  .icon {
    margin-bottom: 1em;
    font-size: 24px;
    color: #252525;
  }
}

/* Responsive width for Auth-form */
@media screen and (max-width: 600px) {
  .Auth-form {
    max-width: 350px;
  }
}
