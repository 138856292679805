.circularViewCard-con {
    background: white;
    border-radius: 50px;
    border: 1px solid grey;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add border shadow
    min-width: 100px;
    padding: 2px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  
    .value-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 5px;
    }
  
    .value-text {
      display: flex;
      flex-direction: column;
    }

    .value-text h1 {
        margin: 0;
        font-size: 16px !important;
        font-family: "poppinsSemiBold" !important;
        color: black !important;
      }
      
      .value-text h2 {
        margin: 0;
        font-size: 10px !important;
        font-family: "poppinsSemiBold" !important;
        color: green !important;
      }
      
  }
  