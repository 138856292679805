.bg-container-con {
  background-image: url("../../images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 100vh;
  padding: 24px 24px;
}

.bg-main {
  padding: 10px 10px;
}
