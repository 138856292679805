.Profile-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  border-radius: 8px;
  padding: 20px 50px 20px 50px;
  background: #f5f5f5;

  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: pink;
    h3 {
      font-size: 1.3rem;
      font-family: "poppinsSemiBold";
      color: grey;
    }
  }
  .Profile-section {
    display: flex;

    .Image-section {
      flex: 1;
      display: flex;
      align-items: flex-start;
    }

    .Image-section img {
      border-radius: 20px;
      max-width: 100%;
    }

    .Info-section {
      flex: 4;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 10px;
      h1 {
        font-size: 1.2rem;
        font-family: "poppinsSemiBold";
        color: purple;
      }

      h2 {
        font-size: 25px;
        font-family: "poppinsSemiBold";
        color: black;
      }

      h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        font-size: 10px;
        font-family: "poppinsSemiBold";
        color: grey;
      }

      h4 {
        font-size: 1.2rem;
        font-family: "poppinsSemiBold";
        color: black;
      }
    }

    .Info-section .info-row {
      display: flex;
      flex-direction: row;
    }
  }

  @media screen and (max-width: 786px) {
    Profile-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 400px;

      .Image-section {
        img {
          height: auto;
          width: 250px;
        }
      }

      .Info-section {
        // text-align: center;
        display: flex;
        flex-direction: column;
      }

      .Info-section .info-row {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .Profile-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 400px;

      .Image-section {
        img {
          height: auto;
          width: 200px;
        }
      }

      .Info-section {
        text-align: center;
      }

      .Info-section .info-row {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.user-form {
  width: 650px;
  padding-top: 50px;
  padding-bottom: 100px;
  border-radius: 12px;
  .user-form-content {
    padding-left: 12%;
    padding-right: 12%;
    .user-input {
      background-color: var(--w1);
      border-radius: 10px;
      border: 1px solid #bcbec0;
      margin-top: 5px;
    }
  }
}
