.AboutUs-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 16px;

  .about-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;

    .text-section {
      flex: 1;
      padding-right: 20px;

      h1 {
        font-size: 28px;
        color: #454545;
        margin: 0;
      }

      p {
        font-size: 16px;
        color: #666;
        margin-top: 16px;
        line-height: 1.5;
      }
    }

    .image-section {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        border-radius: 10px;
      }
    }
  }

  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .about-section {
      flex-direction: column; 
      text-align: center;

      .text-section {
        padding-right: 0;
        margin-bottom: 20px; 
        
        h1 {
          font-size: 24px;
        }

        p {
          font-size: 14px;
        }
      }

      .image-section {
        img {
          width: 200px; 
          height: auto;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .about-section {
      padding: 10px;

      .text-section {
        h1 {
          font-size: 20px;
        }

        p {
          font-size: 12px;
        }
      }

      .image-section {
        img {
          width: 150px;
        }
      }
    }
  }
}
