.top-row-con {
  margin-top: 20px;

  .progress-chart-right-info {
    border-left: 1px solid var(--theme_tertiary_color);
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .last-info-row {
    margin-top: 20px;
    .progress-chart-con {
      width: 170px;
      height: 170px;
    }
  }
}
