@mixin applyPadding($padding) {
  padding: $padding;
}

.commonCardCon {
  background-color: var(--w1);
  border-radius: 24px;
  // box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  border: 2px solid #91bdf3;

  .right-button-1 {
    background-color: var(--w1);
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #bcbec0;
    border-radius: 10px;
    cursor: pointer;
    .common-right-button-icon {
      height: 24px;
      width: 24px;
    }
  }
  .right-button-2 {
    background-color: var(--w1);
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #bcbec0;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.5s ease; // Smooth transition for rotation

    &.clicked {
      transform: rotate(360deg); // 360-degree rotation
      // animation: bounce 0.3s ease;
    }

    .common-right-button-icon {
      height: 24px;
      width: 24px;
    }
  }

  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  h4 {
    font-size: 20px;
  }

  .active-map {
    background-color: #00ff00; /* Set your active slab color */
  }
  .inactive-map {
    background-color: #e74c3c; /* Set your inactive slab color */
  }

  .healthy-map {
    background-color: yellow; /* Set your healthy slab color */
  }
  .text {
    font-size: 14px;
    border-radius: 6px;
    margin-right: 6px;
    margin-top: 5px;
    font-weight: 500;
  }

  .apply-filter-button {
    margin-top: 30px;
  }
}
