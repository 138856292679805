.profile-detail-main-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;

  .title-bar {
    align-items: center;
    // background-color: pink;

    .title-col {
    }

    .title-row {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }

  .user-info {
    padding-top: 8px;
    .name-bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // background-color: pink;

      .name-email-no {
        display: flex;
        flex-wrap: wrap; /* Allows wrapping on smaller screens */
        gap: 10px; /* Adds space between items */
        margin-bottom: 5px;
        // background-color: orchid;
      }

      .address-bar {
        display: flex;
        align-self: flex-start;
        justify-content: flex-end;
        margin-top: 5px;
        // background-color: rgb(112, 181, 218);
      }
    }

    .image-bar {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .profile-image {
        height: 108px;
        width: 108px;
        border-radius: 8px;
        border: 1px solid #bcbec0;
      }
    }
  }

  .user-other-info {
    padding-bottom: 50px;
    .other-info {
      // background-color: gray;

      .progress-view {
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap; /* Allows wrapping for smaller screens */

        .progress-chart-con {
          width: 130px;
          height: 130px;
          margin-right: 10px;
          margin-bottom: 10px; /* Adds spacing between progress charts */
        }
      }

      .vehical-info {
        display: flex;
        margin-top: 10px;
        // flex-direction: column; /* Stacks items vertically */
        padding-left: 0px;

        .three-part-text {
          margin-bottom: 10px; /* Adds spacing between vehicle info items */
        }
      }
    }

    .map-view {
      // background-color: palevioletred;
      margin-top: 20px; //for smaller screen spacing
    }
  }

  /* Media query for screens smaller than 768px (tablets and mobile) */
  @media (max-width: 768px) {
    .title-bar {
      flex-direction: column;
      align-items: flex-start; /* Align the items to the left for smaller screens */

      .title-col {
        width: 100%; /* Make title-col span full width on smaller screens */
        margin-bottom: 10px; /* Add some space between title-col and title-row */
      }

      .title-row {
        justify-content: center; /* Center the items on smaller screens */
        width: 100%; /* Make title-row span full width */
        flex-wrap: wrap; /* Allow elements to wrap and prevent collapsing */
      }
    }

    .name-bar {
      .name-email-no {
        flex-direction: column; /* Stack name, email, phone vertically */
        align-items: flex-start; /* Align text to the left */
        gap: 5px; /* Reduce gap between items */
      }

      .address-bar {
        justify-content: flex-start; /* Align address to the left on smaller screens */
      }
    }

    .image-bar {
      justify-content: center; /* Center the image on smaller screens */
      align-items: center;
      .profile-image {
        height: 90px; /* Adjust image size for smaller screens */
        width: 90px;
      }
    }
    .other-info {
      .progress-view {
        justify-content: space-around; /* Spreads progress charts evenly */
        flex-wrap: wrap; /* Ensures progress charts wrap if necessary */
      }

      .vehical-info {
        padding-left: 0px; /* Removes left padding for smaller screens */
        margin-top: 20px;
      }
    }

    .map-view {
      margin-top: 20px; /* Adds space between the info and map view */
    }
  }

  /* Media query for screens smaller than 480px (mobile phones) */
  @media (max-width: 480px) {
    .title-bar {
      padding: 10px; /* Reduce padding for smaller mobile screens */
    }

    .title-row {
      // background-color: rebeccapurple;
      justify-content: flex-start; /* Space elements evenly on mobile */
      flex-direction: column; /* Stack items vertically */
    }

    .name-bar {
      align-items: flex-start; /* Align the entire name bar to the left */
      margin-bottom: 10px; /* Add space between name-bar and image-bar */

      .name-email-no {
        gap: 5px; /* Reduce space between elements for smaller screens */
      }
    }

    .image-bar {
      justify-content: center; /* Center the image on very small screens */
      .profile-image {
        height: 80px; /* Further reduce image size for very small screens */
        width: 80px;
      }
    }
    .other-info {
      .progress-view {
        justify-content: center; /* Centers progress charts */
        margin-top: 10px; /* Adjusts top margin */
      }

      .progress-chart-con {
        width: 100px; /* Reduces size for smaller screens */
        height: 100px;
        margin-right: 5px;
        margin-bottom: 10px;
      }

      .vehical-info {
        display: block; /* Stack vehicle info vertically */
        padding-left: 0;
        margin-top: 10px;

        .three-part-text {
          margin-bottom: 8px; /* Reduce spacing for small screens */
        }
      }
    }

    .map-view {
      margin-top: 10px; /* Adds spacing between map view and other sections */
    }
  }
}
