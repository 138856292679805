.aiCenter {
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
}
.form-group {
  .form-control {
    height: "100px";
  }
}
