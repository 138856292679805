:root {
  // Theme Color
  --theme_color: #001a33;
  --theme_primary_color: #012d75;
  --theme_secondary_color: #0075ff;
  --theme_tertiary_color: #6c82a6;

  //white
  --w1: #ffffff;
  --w2: #e6e6e6;
  --w3: #f0f0f0;

  //grey
  --g1: #454545;
  --g2: #8c8c8c;
  --g3: #2b2b2a;
  --g4: #f8f8f8;
  --g5: #858585;
  --g6: #525252;
  --g7: #bcbec0;

  //green
  --gr1: #008000;
  --gr2: #467646;

  //red
  --r1: #ee5b5b;
  --r2: #ff0000;
  --r3: #e30b5c;

  //yellow
  --y1: #ffff00;
  --y2: #ffa500;

  //blue
  --b1: #063ac0;
  --b2: #4a71d6;

  //sky
  --sk1: "#91bdf3";

  //gradient
  --grd1: #014d9466;
  --grd2: #001a3366;

  //shadow
  --sh1: #012d7540;
}
